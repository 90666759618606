import "core-js/stable";
import "regenerator-runtime/runtime";
import "../scss/style.scss";

// import Vue from 'vue/dist/vue.min.js';
import Vue from "vue";
import vueDebounce from "vue-debounce/dist/vue-debounce.min";
import barba from "@barba/core/dist/barba";
import select from "dom-select";
import throttle from "lodash.throttle";
import SmoothScroll from "smooth-scroll/dist/smooth-scroll.min";
import Swiper from "swiper/swiper-bundle.min";
import LazyLoad from "lazyload/lazyload.min";
import tippy from "tippy.js/dist/tippy.umd.min";
import Player from "@vimeo/player/dist/player.min";
import Cookie from "js-cookie";
const autocomplete = require("autocomplete.js");

import { Formie } from "../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib";
import { classy as cl } from "./helpers/classy";
import { scrollLock, scrollUnLock } from "./helpers/scrollLock";
import Spoilers from "./helpers/spoilers";
import videoResize from "./helpers/videoResize";
import axios from "./helpers/axios";
import AppList from "./apps/AppList.vue";
import Calculator from "./apps/Calculator.vue";
import icons from "./helpers/icons";
import lax from "lax.js";

Vue.use(vueDebounce);

export const EventBus = new Vue();

const formNameModalAssociation = new Map([
  ["contact", "contactForm"],
  ["trade-in", "tradeInForm_NEW"],
  ["finance", "financeApplication_NEW"],
]);

export const formModalOpenGTMEventObject = (parent, modalTarget) => {
  const financeForm = select(".finance-form-wrap", parent);
  const vehicleDealer = select(
    'input[name="fields[napier]"][value="Yes"],input[name="fields[hastings]"][value="Yes"]',
    financeForm
  );
  let _dealer = "";
  if (vehicleDealer) {
    _dealer = vehicleDealer.name.replace("fields[", "").replace("]", "");
  }
  return {
    event: "formSubmit",
    formStep: "formVisible",
    formType: formNameModalAssociation.get(modalTarget),
    dealer: _dealer,
  };
};

window.vehicles = [];
export function inputs(parent) {
  select.all(".input", parent).forEach((input) => {
    if (input.value !== "") input.classList.add("filled");
    input.addEventListener("focus", function () {
      input.classList.add("filled");
    });
    input.addEventListener("blur", function () {
      if (input.value === "") {
        input.classList.remove("filled");
      }
    });
    input.addEventListener("change", function () {
      input.classList.add("filled");
    });
  });
}

(function (doc, win) {
  require("viewport-units-buggyfill").init();
  window.openModal = false;
  window.dataLayer = window.dataLayer || [];
  const app = {};

  function scLock(target) {
    scrollLock(target, (w) => {
      const header = select(".header");
      if (header) {
        header.style.paddingRight = w + "px";
      }
    });
  }

  function scUnlock() {
    scrollUnLock(() => {
      const header = select(".header");
      if (header) {
        header.style.paddingRight = "";
      }
    });
  }

  function buttonStyles() {
    select
      .all(".button-red, .button-black, .button-white")
      .forEach(function (btn) {
        btn.innerHTML =
          "<span>" +
          btn.innerHTML +
          '</span><svg enable-background="new 0 0 15.6 28.3" viewBox="0 0 15.6 28.3" xmlns="http://www.w3.org/2000/svg" fill=""><path d="m0 0h15.5c-.9 11.3-5.7 20.2-9.6 24-.5.5-2.4 2.1-2.4 2.1-2 1.5-3.5 2.2-3.5 2.2z"/></svg>';
      });
  }

  function parallaxInit() {
    lax.init();

    // Add a driver that we use to control our animations
    lax.addDriver("scrollY", function () {
      return window.scrollY;
    });
  }
  function parallaxRemove() {
    // Unhook from all selectors declared in the parallaxAdd function
    lax.removeElements(
      ".block-banner .bg-image, .block-banner .wysiwyg, .block-banner .wysiwyg h1, .block-banner .wysiwyg h2, .cover-block .bg-image, .cover-block .wysiwyg, .cover-block .wysiwyg h2"
    );
  }
  function parallaxAdd() {
    // Animation on banner bg-image
    lax.addElements(".block-banner .bg-image", {
      scrollY: {
        opacity: [
          ["elInY+600", "elCenterY"],
          [1, 0.5],
        ],
        translateY: [
          ["elInY", "elOutY"],
          [0, -200],
        ],
      },
    });

    // Animation on banner text block
    lax.addElements(".block-banner .wysiwyg", {
      scrollY: {
        opacity: [
          ["elInY", "elCenterY"],
          [0, 1],
        ],
        translateY: [
          ["elInY", "elCenterY"],
          [0, -50],
        ],
      },
    });

    // Animation on banner headlines to meet with text
    lax.addElements(".block-banner .wysiwyg h1,.block-banner .wysiwyg h2", {
      scrollY: {
        opacity: [
          ["elInY", "elCenterY"],
          [0.2, 1],
        ],
        translateY: [
          ["elInY", "elCenterY"],
          [-50, 0],
        ],
      },
    });

    // Animation on cover bg-image
    lax.addElements(".cover-block .bg-image", {
      scrollY: {
        opacity: [
          ["elCenterY", "elOutY"],
          [1, 0.3],
        ],
        translateY: [
          ["elCenterY", "elOutY"],
          [0, 100],
        ],
      },
    });

    // Animation on cover block exit
    lax.addElements(".cover-block .wysiwyg", {
      scrollY: {
        opacity: [
          ["elCenterY", "elOutY"],
          [1, 0.5],
        ],
        translateY: [
          ["elCenterY", "elOutY"],
          [0, 150],
        ],
      },
    });

    // Animation on headlines to meet with text
    lax.addElements(".cover-block .wysiwyg h2", {
      scrollY: {
        translateY: [
          ["elInY", "elOutY"],
          [50, 0],
        ],
      },
    });
  }

  const nav = function () {
    const burgers = select.all(".burger");
    const header = select(".header");
    const nav = select(".nav", header);
    const dropdowns = select.all(".dropdown", header);
    const searchTrig = select(".nav-search-trig", header);
    const searchBlock = select(".block-search.is-global");
    const search = select(".search-input", searchBlock);
    const message = select("#message");
    let vehiclePage = false;

    if (message) {
      const messageClose = select("#message .close");
      messageClose.addEventListener("click", function () {
        message.style.opacity = 0;
        setTimeout(() => {
          message.remove();
        }, 200);
      });
    }

    function lock() {
      window.openModal = header;
      scLock(header);
    }

    function unlock() {
      window.openModal = false;
      scUnlock();
    }

    burgers.forEach((burger) => {
      burger.addEventListener("click", function () {
        if (burger.classList.contains("open")) {
          unlock();
        } else {
          lock();
        }
        cl(header).toCl("open");
        cl(burgers).toCls("open");
      });
    });

    // Dropdown menu close
    select("body").addEventListener("click", function (ev) {
      if (
        !(
          ev.target.classList.contains("head-nav") ||
          ev.target.classList.contains("select-drop")
        )
      ) {
        cl(dropdowns).rmCls("open");
      }
    });
    // Dropdown menu close
    dropdowns.forEach((d) => {
      d.addEventListener("click", function () {
        if (d.classList.contains("open")) {
          cl(dropdowns).rmCls("open");
        } else {
          cl(dropdowns).rmCls("open");
          cl(d).addCl("open");
        }
      });
    });

    select.all(".nav-search-trig", header).forEach((trig) => {
      if (search) {
        trig.addEventListener("click", () => {
          if (!searchBlock.classList.contains("show-search")) {
            setTimeout(() => {
              search.focus();
            }, 100);
          }
          cl(searchBlock).toCl("show-search");
          cl(header).toCl("search-is-visible");
        });
      }
    });

    this.close = function () {
      if (header.classList.contains("open")) {
        cl(header).rmCl("open");
        cl(burgers).rmCls("open");
        unlock();
      }
    };
    this.leave = function () {
      if (message) {
        message.style.opacity = 0;
      }
    };

    this.update = function (next) {
      let parent = next ? next.container : "";
      if (message) {
        message.style.opacity = 1;
        if (select(".barba-wrapper .block-search", parent)) {
          cl(message).addCl("has-fixed-search-bar");
        } else {
          cl(message).rmCl("has-fixed-search-bar");
        }
      }

      const path = next ? next.url.path : window.location.pathname;
      if (path.split("/vehicle/").length > 1) {
        vehiclePage = true;
      } else {
        vehiclePage = false;
      }
    };

    this.update();

    this.scroll = function () {
      const scrollDeep = 75;
      if (
        window.scrollY > scrollDeep &&
        !header.classList.contains("is-compact")
      ) {
        cl(header).addCl("is-compact");
        if (message) cl(message).addCl("is-scrolling");
      } else if (
        window.scrollY <= scrollDeep &&
        header.classList.contains("is-compact")
      ) {
        cl(header).rmCl("is-compact");
        if (message) cl(message).rmCl("is-scrolling");
      }
    };
  };

  function activeNav() {
    const firstSegment = location.pathname.split("/")[1];
    const lastSegment = location.pathname.split("/").pop();

    select.all(".header a, .nav a, .footer a").forEach(function (nav) {
      const linkSegments = nav.pathname.split("/");
      const linkFirstSegment =
        linkSegments.length > 1 && linkSegments[1] !== ""
          ? linkSegments[1]
          : false;
      const linkSecondSegment =
        linkSegments.length > 2 && linkSegments[2] !== ""
          ? linkSegments[2]
          : false;

      if (linkSecondSegment && linkSecondSegment === lastSegment) {
        cl(nav).addCl("active");
      } else if (
        linkFirstSegment &&
        !linkSecondSegment &&
        linkFirstSegment === firstSegment
      ) {
        cl(nav).addCl("active");
      } else {
        cl(nav).rmCl("active");
      }
    });

    // dropdown menu make trigger active
    select.all(".dropdown").forEach((d) => {
      const trigger = select(".head-nav", d);
      cl(trigger).rmCl("active");
      select.all(".select-drop a", d).forEach((a) => {
        if (a.classList.contains("active")) {
          cl(trigger).addCl("active");
        }
      });
    });
  }

  function smooth(el, offset, speed) {
    if (el) {
      const scroll = new SmoothScroll();
      scroll.animateScroll(el, false, {
        offset: offset || 200,
        updateURL: false,
        speed: speed || 600,
        durationMax: speed || 600,
      });
    }
  }

  function setSmooth(parent) {
    select.all(".smooth-scroll", parent).forEach((anchor) => {
      anchor.addEventListener("click", (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (anchor.dataset.window) {
          smooth(window.innerHeight, 0, 1000);
        } else {
          const target = select(anchor.hash);
          if (target) smooth(target, 76, 1000);
        }
      });
    });
  }

  function checkSearch(searchString, query) {
    return query.split(" ").every((q) => {
      return searchString.split(" ").some((s) => {
        return s.toUpperCase().indexOf(q.toUpperCase()) > -1;
      });
    });
  }

  function highlight(input, text) {
    var re = new RegExp(input.split(" ").join("|"), "gi");
    return text.replace(re, function (matched) {
      return `<span class="highlight">${matched}</span>`;
    });
  }

  function setAutocompletes(parent, isHeader) {
    if (!isHeader && typeof google !== 'undefined') {
      const service = new google.maps.places.AutocompleteService();
      autocomplete(
        ".address-autocomplete input",
        {
          hint: false,
        },
        [
          {
            source: (input, callback) => {
              service.getPlacePredictions(
                {
                  input,
                  componentRestrictions: { country: "nz" },
                },
                (results) => {
                  if (results) results.forEach((r) => (r._input = input));
                  callback(results);
                }
              );
            },
            displayKey: "description",
            debounce: 600,
            templates: {
              suggestion: function (suggestion) {
                const regExp = new RegExp("(" + suggestion._input + ")", "giu");
                return suggestion.description.replace(
                  regExp,
                  '<span class="highlight">$1</span>'
                );
              },
            },
          },
        ]
      ).on("autocomplete:selected", function (event, suggestion) {
        event.target.value = suggestion.description;
      });
    }

    let wrap = null;
    let scrollDrop = null;
    let suggestions = [];
    let showAll = null;
    const search = autocomplete(
      isHeader
        ? ".block-search.is-global .search-input"
        : ".barba-wrapper .block-search .search-input",
      {
        hint: false,
        openOnFocus: true,
        debug: false,
        minLength: 3,
        clearOnSelected: true,
        templates: {
          dropdownMenu: `
          <div class="search-dropdown">
            <div class="show-all-wrap">
              <div class="cont">
                <div class="show-all"><a href="" class="link" data-barba-prevent>Show all results</a></div>  
              </div>
            </div>
            <div class="cont"><div class="aa-dataset-search search-dropdown-scroll"></div></div>
          </div>
        `,
        },
      },
      [
        {
          source: (input, callback) => {
            if (window.vehicles.length) {
              const results = window.vehicles.reduce((vehicles, vehicle) => {
                if (checkSearch(vehicle.search, input)) {
                  vehicle._input = input;
                  vehicles.push(vehicle);
                }
                return vehicles;
              }, []);
              callback(results);
            } else {
              callback([]);
            }
          },
          name: "search",
          displayKey: "title",
          debounce: 100,
          templates: {
            suggestion: function (suggestion) {
              return highlight(suggestion._input, suggestion.title);
            },
            empty: function ({ query }) {
              return `<div class="no-results">No results for: <span class="is-bold text-red">${query}</span></div>`;
            },
          },
        },
      ]
    )
      .on("autocomplete:selected", function (event, suggestion) {
        event.target.value = suggestion.title;
        barba.go(suggestion.url);
      })
      .on("autocomplete:shown", function (event) {
        wrap = event.target.closest(".algolia-autocomplete");
        scrollDrop = select(".search-dropdown-scroll", wrap);
        suggestions = select.all(".aa-suggestion", scrollDrop);
        showAll = select(".show-all .link", wrap);
        if (wrap) {
          cl(wrap).addCl("filled");
        }
        if (window.innerWidth < 1024) {
          const cont = search[0].closest(".block-search");
          if (cont) {
            scLock(cont);
          }
        }
      })
      .on("autocomplete:updated", function (e) {
        if (showAll) {
          localStorage.removeItem("vehicles_search");
          localStorage.setItem("vehicles_search", this.value);

          if (window.location.pathname == "/vehicles") {
            showAll.removeEventListener("click");
            showAll.addEventListener(
              "click",
              function (e) {
                localStorage.removeItem("vehicles_make");
                localStorage.removeItem("vehicles_model");
                localStorage.removeItem("vehicles_yearFromYear");
                localStorage.removeItem("vehicles_yearToYear");
                localStorage.removeItem("vehicles_bodyStyle");

                app.appList.route = window.location.hash;
              },
              false
            );
          } else {
            showAll.href = "/vehicles#/search:" + this.value;
          }
        }
        if (e.target.value.length < 3) {
          scUnlock();
        }
      })
      .on("autocomplete:cursorchanged", function (event) {
        const cursorIndex = suggestions.findIndex((s) =>
          s.classList.contains("aa-cursor")
        );
        const cursor = cursorIndex > -1 ? suggestions[cursorIndex] : null;
        if (cursor && scrollDrop) {
          const cursorRect = cursor.getBoundingClientRect();
          const dropRect = scrollDrop.getBoundingClientRect();
          if (cursorRect.top < dropRect.top) {
            scrollDrop.scrollTop = cursorRect.height * cursorIndex;
          } else if (cursorRect.bottom > dropRect.bottom) {
            scrollDrop.scrollTop =
              cursorRect.height * cursorIndex - cursorRect.height * 9;
          }
        }
      })
      .on("autocomplete:closed", function () {
        scUnlock();
      });

    select.all(".block-search").forEach((s) => {
      const clear = select(".close", s);
      const input = select(".search-input", s);
      const wrap = select(".algolia-autocomplete", s);

      if (clear && search && input && wrap) {
        clear.addEventListener("click", () => {
          search.autocomplete.close();
          search.autocomplete.setVal("");
          cl(wrap).rmCl("filled");
          scUnlock();

          cl(s).rmCl("show-search");
          cl(".header").rmCl("search-is-visible");
        });

        input.addEventListener("focus", () => {
          cl(wrap).addCl("filled");
        });

        s.addEventListener("click", () => {
          if (!s.classList.contains("is-global")) {
            const offset = window.innerWidth >= 1024 ? 80 : 70;
            if (s.getBoundingClientRect().top > offset) {
              smooth(s, offset);
              setTimeout(() => {
                input.focus();
              }, 700);
            }
          }
        });

        input.addEventListener("blur", () => {
          cl(wrap).rmCl("filled");
        });

        input.addEventListener("keypress", function (e) {
          if (showAll && e.keyCode == 13) {
            showAll.click();
          }
        });

        input.addEventListener("input", (ev) => {
          if (ev.target.value.length === 0) {
            cl(wrap).rmCl("filled");
          }
        });
      }
    });
  }

  function setFormEvents(Formie, parent) {
    const financeSpoiler = select(".finance-spoiler", parent);
    const thanksSpoiler = select(".finance-thanks", parent);
    const financeForm = select(".finance-form-wrap", parent);
    const allPagesCompleted = select(
      'input[name="fields[allPagesCompleted]"]',
      financeForm
    );
    const vehicleDealer = select(
      'input[name="fields[napier]"][value="Yes"],input[name="fields[hastings]"][value="Yes"]',
      financeForm
    );

    if (Formie) {
      Formie.forms.forEach((form) => {
        //console.log(form.config.formHandle + 'setting up')

        const pages = select.all(".fui-page", form.$form);
        const activeIndex = pages.findIndex(
          (p) => !p.classList.contains("fui-hidden")
        );

        let pageSubmitted = [0];

        if (activeIndex > 0) {
          cl(financeSpoiler).rmCl("active");
        }

        form.$form.addEventListener("onAfterFormieSubmit", (e) => {
          const data = e.detail;

          const modalScroll = form.$form.closest(".modal");
          if (modalScroll) {
            modalScroll.scrollTo(0, 0);
          }

          let formEvent = "FormSubmission";
          let sendEvent = true;
          let _dealer = "";
          if (vehicleDealer) {
            _dealer = vehicleDealer.name
              .replace("fields[", "")
              .replace("]", "");
          }

          if (allPagesCompleted) allPagesCompleted.value = "No";

          // either on a single page form or we're on the last page.
          if (
            data.nextPageId == null ||
            data.nextPageIndex == data.totalPages - 1
          ) {
            //on the last page of the form, so set the all pages completed value to yes.
            // set allPagesCompleted to true
            if (allPagesCompleted) allPagesCompleted.value = "Yes";
          }

          if (data.nextPageId !== null) {
            formEvent = `SubmitPage${data.nextPageIndex}`;

            if (pageSubmitted.indexOf(data.nextPageIndex) > -1) {
              //page has already been submitted - user must have clicked the back button
              sendEvent = false;
            } else {
              //add to the array so we know if we hit the back button, don't trigger a send.
              pageSubmitted.push(data.nextPageIndex);
            }
          }

          // console.log({
          //   'pageSubmitted': pageSubmitted,
          //   'sendEvent' : sendEvent,
          //   formStep: formEvent,
          //   formType: form.config.formHandle,
          //   dealer: _dealer
          // });
          if (sendEvent) {
            let toPush = {
              event: "formSubmit",
              formStep: formEvent,
              formType: form.config.formHandle,
              dealer: _dealer,
            };

            //console.log(toPush);

            window.dataLayer = window.dataLayer || [];
            dataLayer.push(toPush);
          }

          if (data.success && data.nextPageId === null) {
            form.$form.classList.add("is-submitted");
            if (financeForm) cl(financeForm).addCl("is-submitted");
            if (thanksSpoiler) cl(thanksSpoiler).addCl("active");
          }

          if (
            data.nextPageIndex === 0 &&
            financeSpoiler &&
            data.nextPageId !== null
          ) {
            cl(financeSpoiler).addCl("active");
          } else if (financeSpoiler) {
            cl(financeSpoiler).rmCl("active");
          }
        });

        form.$form.addEventListener('onFormieValidate', (e) => {
          const regoField = select('[name="fields[regoLookup]"]', form.$form);

          if (regoField) {
            if (regoField.value) {
              e.preventDefault();
              doLookUp(parent, true).then(() => {
                setTimeout(() => (e.detail.submitHandler.submitForm()), 100);
              })
            }
          }
        });

        select.all(".fui-instructions", form.$form).forEach((inst) => {
          const clone = inst.cloneNode(true);
          const label = select("label", inst.parentNode);
          const innerItem = select("code", clone);
          const innerText = innerItem ? innerItem.innerHTML.split("|") : [];

          if (clone && label && !innerText.length) {
            label.appendChild(clone);
            inst.remove();
          }

          if (label && clone && innerText.length > 1) {
            innerItem.innerHTML = innerText[0];
            label.appendChild(clone);
            inst.remove();
            tippy(innerItem, {
              content: innerText[1],
            });
          }
        });
      });
    }
  }

  function setFormValidations(form) {
    form.addEventListener("registerFormieValidation", (e) => {
      e.preventDefault();

      e.detail.validatorSettings.customValidations = {
        ...e.detail.validatorSettings.customValidations,
        ...{
          licenceNo(field) {
            return field.name === "fields[licenseNumber]"
              ? !RegExp(/^[a-zA-Z]{2}[0-9]{6}$/).test(field.value)
              : false;
          },
          licenceVersion(field) {
            return field.name === "fields[version]"
              ? !RegExp(/^[0-9]{3}$/).test(field.value)
              : false;
          },
          aNumber(field) {
            return field.dataset.validation &&
              field.dataset.validation === "number"
              ? !RegExp(/[0-9]{3}/).test(field.value)
              : false;
          },
          regoLookup(field) {
            if (field && field.name !== "fields[regoLookup]") return false;

            const emptyRegoFields = select.all('.rego-field', form).some((w) => {
              const f = select('input', w);
              return !f.value && f.hasAttribute('required')
            });

            return field.classList.contains('lookup-error') && emptyRegoFields;
          }
        },
      };

      e.detail.validatorSettings.messages = {
        ...e.detail.validatorSettings.messages,
        ...{
          licenceNo: "Not valid Licence No. Expected format AB123456",
          licenceVersion: "Not valid Licence Version. Expected format 123",
          aNumber: "Should be a 3 digits",
          regoLookup(field) {
            return field.getAttribute('data-rego-error');
          }
        },
      };
    });
  }

  const isEmpty = function (obj) {
    return Object.keys(obj).length === 0;
  };

  app.forms = null;
  function initForm(parent) {
    app.forms = new Formie();
    if (app.forms) {
      const forms = select.all(".fui-form", parent);

      forms.forEach((form, key, forms) => {
        setFormValidations(form);
        app.forms.initForm(form);

        if (Object.is(forms.length - 1, key)) {
          //for some reason - this must be called after all the forms have been run??
          //console.info('setting form line 669');
          setFormEvents(app.forms, parent);
        }
      });
    }
  }

  function setJuicerData(slide, index) {
    const cols = select.all(".feed-post-tile", slide);
    if (cols.length && !cols[0].classList.contains("loaded")) {
      axios(
        `https://www.juicer.io/api/feeds/${window.feedName}?per=3&page=${
          index + 1
        }`
      ).then(({ data }) => {
        cols.forEach((p, i) => {
          cl(p).addCl("loaded");
          const post = data.posts.items.length ? data.posts.items[i] : null;
          if (post) {
            const image = select(".div-image", p);
            const message = select(".message", p);
            const icon = select(".icon", p);

            const postImage = post["image"]
              ? post["image"]
              : post["poster_image"]
              ? post["poster_image"]
              : "";
            const video =
              post["video"] && post["video"].indexOf("youtube") === 1
                ? post["video"]
                : postImage && postImage.indexOf(".mp4") > -1
                ? postImage
                : null;

            if (video) {
              cl(p).addCl("as-video");
              image.innerHTML = `
                <video src="${video}" autoplay muted playsinline loop></video>
              `;
            } else {
              image.style.backgroundImage = `url('${postImage}')`;
            }
            message.innerHTML = post.message;
            icon.href = post.full_url;

            switch (post.source.source) {
              case "Instagram":
                icon.innerHTML = icons.instagram;
                break;
              case "Facebook":
                icon.innerHTML = icons.facebook;
                break;
              case "YouTube":
                icon.innerHTML = icons.youtube;
                break;
              case "Twitter":
                icon.innerHTML = icons.twitter;
                break;
              case "LinkedIn":
                icon.innerHTML = icons.linkedin;
                break;
            }
          }
        });
      });
    }
  }

  app.swipers = [];
  function swipers(parent) {
    app.swipers.forEach((sw) => sw.destroy());

    function slideChange(sw) {
      window.modals.forEach((m) => m.videos.forEach((v) => v.pause()));
      const id = sw.$el ? sw.$el[0].dataset.swiperId : false;
      const active = sw.activeIndex;

      if (id && active) {
        app.swipers.forEach((swiper) => {
          const otherId = swiper.$el ? swiper.$el[0].dataset.swiperId : false;

          if (otherId && swiper.activeIndex !== active && id === otherId) {
            swiper.slideTo(active);
          }
        });
      }
    }

    select.all(".vehicle-gallery", parent).forEach((wrapper) => {
      const mainEl = select(".vehicle-gallery-main", wrapper);
      const thumbsEl = select(".vehicle-gallery-thumbnails", wrapper);
      const isModal = select(".modal .vehicle-gallery-thumbnails", wrapper);
      // TO DO: detect mobile for gallery pop up
      //console.log((thumbsEl && mainEl));
      if (thumbsEl && mainEl) {
        const vertical = thumbsEl.classList.contains("is-vertical");

        const thumbs = new Swiper(thumbsEl, {
          direction: vertical ? "vertical" : "horizontal",
          speed: 600,
          slidesPerView: isModal ? 8 : 4,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          shortSwipes: true,
          threshold: 10,
          loop: true,
          // passiveListeners: false,
          // preventClicksPropagation: false,
          allowTouchMove: true,
          breakpoints: {
            768: {
              slidesPerView: isModal ? 10 : 5,
            },
            1200: {
              slidesPerView: isModal ? 12 : 5,
            },
          },
        });

        app.swipers.push(
          new Swiper(mainEl, {
            effect: "fade",
            speed: 800,
            navigation: {
              nextEl: select.all(".swiper-nav-next", wrapper),
              prevEl: select.all(".swiper-nav-prev", wrapper),
            },
            on: {
              slideChange,
              beforeDestroy() {
                thumbs.destroy();
              },
              init() {
                setTimeout(() => {
                  thumbs.update();
                }, 1000);
              },
            },
            thumbs: {
              swiper: thumbs,
            },
          })
        );
      }
    });

    select.all(".swiper-quote", parent).forEach((sw) => {
      app.swipers.push(
        new Swiper(sw, {
          autoplay: {
            delay: 6000,
          },
          speed: 600,
          spaceBetween: 0,
          effect: "fade",
          breakpoints: {
            1024: {
              spaceBetween: 0,
            },
          },
          loop: true,
          navigation: {
            nextEl: select(".swiper-nav-next", sw.closest(".swiper-wrap")),
            prevEl: select(".swiper-nav-prev", sw.closest(".swiper-wrap")),
          },
        })
      );
    });

    select.all(".swiper-regular", parent).forEach((sw) => {
      let speed = Number(sw.dataset.speed) || 1000;
      let autoplay = Number(sw.dataset.autoplay) || false;

      app.swipers.push(
        new Swiper(sw, {
          speed: speed,
          autoplay: autoplay
            ? {
                delay: autoplay,
              }
            : false,
          loop: autoplay,
          pagination: {
            el: select(".swiper-pagination", sw),
            type: "bullets",
          },
        })
      );
    });

    select.all(".feed-swiper", parent).forEach((sw) => {
      app.swipers.push(
        new Swiper(sw, {
          speed: 1000,
          spaceBetween: 25,
          breakpoints: {
            1024: {
              spaceBetween: 90,
            },
          },
          navigation: {
            nextEl: select(".swiper-nav-next", sw.closest(".swiper-wrap")),
            prevEl: select(".swiper-nav-prev", sw.closest(".swiper-wrap")),
          },
          virtual: {
            cache: true,
            slides: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            renderSlide() {
              return `
                <div class="swiper-slide">
                  <div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="feed-post-tile">
                          <div class="div-image"></div>
                          <div class="message"></div>
                          <a href="#" class="icon" target="_blank"></a>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="feed-post-tile">
                          <div class="div-image"></div>
                          <div class="message"></div>
                          <a href="#" class="icon" target="_blank"></a>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="feed-post-tile">
                          <div class="div-image"></div>
                          <div class="message"></div>
                          <a href="#" class="icon" target="_blank"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              `;
            },
          },
          on: {
            init() {
              this.slides.forEach(setJuicerData);
            },
            slideChangeTransitionEnd() {
              if (this.virtual.slides.length > this.activeIndex + 1) {
                setJuicerData(this.slides.pop(), this.activeIndex + 1);
              }
            },
          },
        })
      );
    });

    setTimeout(function () {
      app.swipers.forEach((sw) => sw.update());
    }, 200);
  }


function iframeVideos(parent) {
  return select.all(".iframe-video", parent).map((videoWrap) => {
    const videoId = videoWrap.dataset.videoId;
    const videoType = videoWrap.dataset.videoType;
    let pl = null;

    if (videoType === "youtube") {
      // Ensure the YouTube API is loaded
      if (typeof YT !== 'undefined' && YT.Player) {
        const player = new YT.Player(videoWrap, {
          videoId: videoId,
          events: {
            'onReady': function (event) {
              // Player is ready
            },
            'onStateChange': function (event) {
              // Player state changed
            }
          }
        });
        
        if (player) {
          pl = {
            wrap: videoWrap.parentNode,
            play() {
              player.playVideo();
            },
            pause() {
              player.pauseVideo();
            }
          };
        }
      } else {
        // Handle the case where YT is not ready
        console.error("YouTube API is not loaded.");
      }
    } else if (videoType === "vimeo") {
      const player = new Player(videoWrap, { id: videoId });
      if (player) {
        pl = {
          wrap: videoWrap.parentNode,
          play() {
            player.play();
          },
          pause() {
            player.pause();
          }
        };
      }
    }

    return pl;
  });
}

// Make sure the YouTube IFrame API is ready before initializing videos
function onYouTubeIframeAPIReady() {
  iframeVideos(document);
}



function openModal(modal) {
  if (window.activeModal) {
    closeModal(window.activeModal);
  }
  if (!modal.open) {
    modal.open = true;
    window.activeModal = modal;
    cl(modal.modal).addCl("open");
    scLock(modal.modal);

    if (modal.videos.length) {
      modal.videos.forEach((v) => {
        if (v.wrap && v.wrap.closest(".swiper-slide-active")) {  // Check if v.wrap exists
          v.play();
        } else if (v.wrap && !v.wrap.closest(".swiper-slide")) { // Ensure v.wrap exists before calling closest
          v.play();
        }
      });
    }
  }

  if (modal.modal.classList.contains("gallery-modal") && app.swipers.length) {
    setTimeout(() => {
      app.swipers.forEach((m) => m.update());
    }, 400);
  }
}


  function closeModal(modal) {
    if (modal.open) {
      modal.open = false;
      window.activeModal = null;
      cl(modal.modal).rmCl("open");
      setTimeout(() => {
        modal.modal.scrollTo(0, 0);
        scUnlock();
      }, 300);
      if (modal.videos.length) {
        modal.videos.forEach((v) => v.pause());
      }
      if (modal.delayed) {
        Cookie.set("modal-closed-" + modal.name, "closed", {
          expires: modal.cookieTime,
        });
      }
    }
  }

  function setButtons(parent) {
    if (window.innerWidth < 700) {
      select.all(".button", parent).forEach((button) => {
        const height = button.offsetHeight;
        let span = select("span", button);

        const newSpan = document.createElement("span");
        newSpan.appendChild(span);
        button.prepend(newSpan);

        let innerSpan = select("span span", button);
        let newHeight = (height - innerSpan.offsetHeight) / 2;

        innerSpan.style.paddingTop = newHeight + "px";
        newSpan.style.paddingTop = "0px";
      });
    }
  }

  function setModals(parent) {
    window.modals = [];
    select.all("[data-modal]", parent).forEach((modal) => {
      const videos = iframeVideos(modal);
      const open = modal.classList.contains("open");
      const name = modal.dataset.modal;
      const delayed = modal.dataset.modalDelayed
        ? Number(modal.dataset.modalDelayed)
        : null;
      const cookieTime = modal.dataset.modalCookieTime
        ? Number(modal.dataset.modalDelayed)
        : null;

      const modalEl = { name, modal, videos, open, delayed, cookieTime };
      window.modals.push({
        ...modalEl,
        open() {
          openModal(modalEl);
        },
        close() {
          closeModal(modalEl);
        },
      });

      select.all(".close, .modal-back, .close-button", modal).forEach((cl) => {
        cl.addEventListener("click", (ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          closeModal(modalEl);
        });
      });

      if (delayed && !Cookie.get("modal-closed-" + modalEl.name)) {
        setTimeout(() => {
          openModal(modalEl);
        }, delayed * 1000);
      }
    });

    select
      .all('[data-modal-target], a[href*="#modal-"]', parent)
      .forEach((trigger) => {
        const target = trigger.dataset.modalTarget
          ? window.modals.find((m) => m.name === trigger.dataset.modalTarget)
          : window.modals.find(
              (m) => m.name === trigger.hash.split("modal-").pop()
            );
        if (target) {
          trigger.addEventListener("click", (ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            window.dataLayer = window.dataLayer || [];
            dataLayer.push(
              formModalOpenGTMEventObject(parent, trigger.dataset.modalTarget)
            );
            target.open();
          });
        }
      });
  }

  function doLookUp(parent, skipError = false) {
    const input = select('[name="fields[regoLookup]"]', parent);
    if (!input) {
      return Promise.resolve(null)
    }

    if (input.classList.contains('looked-up')) {
      return Promise.resolve({ success: true })
    }

    const inputRow = input.closest('.fui-row');
    const lookupButton = select('.rego-button .button', inputRow);
    const inputCont = input.closest('.fui-field-container', inputRow);
    const form = input.closest('.fui-form', inputRow);
    const fields = select.all('.rego-field', parent);
    input.classList.remove('fui-error');
    input.classList.remove('lookup-error');

    lookupButton.classList.add('is-loading');
    return fetch('/actions/stephen-hill-motors-module/carjam', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': window.csrfTokenValue
      },
      body: JSON.stringify({ rego: input.value })
    }).then((r) => r.json()).then((resp) => {
      lookupButton.classList.remove('is-loading');

      input.removeAttribute('data-rego-error');
      const error = select('.fui-error-message', inputCont)
      if (error) {
        error.remove();
      }

      fields.forEach((f) => {
        f.classList.add('showed-field')
      })

      if (resp.success && resp.vehicle) {
        fields.forEach((f) => {
          const field = select('input', f);
          if (field) field.value = ''
        })

        input.classList.add('looked-up');
        Object.entries(resp.vehicle).forEach(([key, value]) => {
          const field = select(`[name="fields[${key}]"]`, form)

          if (field) {
            field.value = value;
          }
        })
      }

      else {
        input.setAttribute('data-rego-error', resp.message);
        input.classList.add('lookup-error');

        if (inputCont && !skipError) {
          const div = document.createElement('div')
          div.classList.add('fui-error-message')
          div.innerText = resp.message
          inputCont.appendChild(div)
        }
      }

      return resp;
    })
  }

  function setRegoLookupFields(parent) {
    const input = select('[name="fields[regoLookup]"]', parent);
    if (!input) {
      return
    }

    const inputRow = input.closest('.fui-row');
    const lookupButton = select('.rego-button', inputRow);

    if (input) {
      input.addEventListener('input', (ev) => {
        if (ev.target.value.length > 3) {
          lookupButton.classList.add('showed-button')
        } else {
          lookupButton.classList.remove('showed-button')
        }

        input.classList.remove('looked-up')
      })
    }

    if (lookupButton) {
      lookupButton.addEventListener('click', () => {
        doLookUp(parent)
      })
    }
  }

  app.lazyload = null;
  function setLazy(parent) {
    if (app.lazyload) app.lazyload.destroy();
    const images = select.all("[data-src]", parent);
    app.lazyload = new LazyLoad(images);
  }

  app.appList = null;
  app.calculators = [];
  function apps(parent) {
    if (app.appList) app.appList.$destroy();
    if (app.calculators.length > 0) {
      app.calculators.forEach((calc) => {
        calc.$destroy();
      });
    }
    const appListEl = select("#app-list", parent);
    const injectedBanner = select(".delivery-banner", parent);
    const searchTrig = select(".nav-search-trig");
    if (appListEl) {
      // //do we have a search in the header as well?
      if (searchTrig) {
        searchTrig.style.opacity = 0;
        searchTrig.style.width = 0;
        searchTrig.style.height = 0;
        searchTrig.style.padding = 0;
      }

      //
      /**
       * Need to add...
       * 
       * 1: add the data points
       *   isHot = appListEl.dataset.ishot ? appListEl.dataset.ishot : false;
       *   allVehicles = appListEl.dataset.allvehicles ? appListEl.dataset.allvehicles : false;
       * 
       * 2: in the vue render use the allVehicles/isHot/saleOnly values to determin what vehciles to show in src/js/apps/AppList.vue
       */
      

      let dataValues = {
        vehicles: window.vehicles.length ? window.vehicles : [],
        performance: appListEl.dataset.performance,
        search: appListEl.dataset.search,
        showFilters: appListEl.dataset.showfilters
          ? appListEl.dataset.showfilters
          : true,
        defaultSortOrder: appListEl.dataset.defaultsortorder,
        stockNos: appListEl.dataset.stocknos
          ? JSON.parse(appListEl.dataset.stocknos)
          : false,
        saleOnly: appListEl.dataset.sale ? appListEl.dataset.sale : true,
        isHot: appListEl.dataset.ishot ? appListEl.dataset.ishot : true,
        onSaleOrIsHot: appListEl.dataset.onsaleorishot ? appListEl.dataset.onsaleorishot : "false",
        specificDealer: appListEl.dataset.dealer
          ?  parseInt(appListEl.dataset.dealer)
          : false,
        banner: injectedBanner ? injectedBanner.outerHTML : "",
        hash: window.location.hash,

      }
      // console.log(appListEl.dataset);
      // console.log(dataValues);

      app.appList = new Vue({
        el: appListEl,
        data: dataValues,
        render: (h) => h(AppList),
      });
    } else {
      if (searchTrig && searchTrig.style.opacity == 0) {
        searchTrig.style.removeProperty("opacity");
        searchTrig.style.removeProperty("width");
        searchTrig.style.removeProperty("height");
        searchTrig.style.removeProperty("padding");
      }
    }
    const appCalculatorEl = select.all(".app-calculator", parent);

    var calc, isModal;
    if (appCalculatorEl.length > 0) {
      appCalculatorEl.forEach((calcEl, i) => {
        isModal = calcEl.closest(".modal");
        calc = new Vue({
          el: calcEl,
          render: (h) =>
            h(Calculator, {
              props: {
                price: calcEl.dataset.price ? Number(calcEl.dataset.price) : 0,
                was: calcEl.dataset.price ? Number(calcEl.dataset.was) : 0,
                isModal: isModal ? true : false,
                index: i,
              },
              ref: "calc",
            }),
        });
        app.calculators.push(calc);
      });
    }
  }

  function equal_team_cols(parent) {
    var hasTeams = select.all(".team-columns", parent).length;
    if (hasTeams == 0) return;

    if (
      select(".row.team-columns .col-md-6").offsetWidth ==
      select(".row.team-columns").offsetWidth
    ) {
      // single col - don't want a resize.
      select.all(".team-section", parent).forEach((colsetAdj) => {
        colsetAdj.style.height = "auto";
      });
    } else {
      select
        .all(".row.team-columns .column-1 .team-section", parent)
        .forEach((input) => {
          var h = 0,
            selector = ".team-section-" + input.dataset.index,
            count = document.querySelectorAll(selector).length;

          select.all(selector, parent).forEach((colset) => {
            colset.style.height = "auto";

            if (colset.offsetHeight > h) {
              h = colset.offsetHeight;
            } else if (colset.offsetHeight == h) {
              h = 0;
            }
            count--;
            if (count == 0 && h > 0) {
              select.all(selector, parent).forEach((colsetAdj) => {
                colsetAdj.style.height = h + "px";
              });
            }
          });
        });
    }
  }

  function nav_phone(parent) {
    var singleDealer = select.all(".blocks[data-dealer]", parent);

    if (singleDealer.length == 1) {
      var phoneIdent = singleDealer[0].dataset.dealer;
    } else {
      var phoneIdent = "global";
    }

    var ph = select.all("a.nav-phone");
    phoneIdent =
      "phone" + phoneIdent[0].toUpperCase() + phoneIdent.substring(1);
    var phTitle = JSON.parse(ph[0].dataset[phoneIdent]);
    if (ph[0].innerHTML != "<span>" + phTitle.phone_link + "</span>") {
      ph[0].innerHTML = "<span>" + phTitle.phone_link + "</span>";
      ph[0].href = "tel:" + phTitle.phone_href;
    }
  }

  function outBarba() {
    app.nav = new nav();
    setAutocompletes(select(".header"), true);
    axios("/api/vehicles.json").then(({ data }) => {
      const vehicles = data;
      if (vehicles.length) {
        window.vehicles = vehicles;
        if (app.appList) {
          app.appList.vehicles = vehicles;
        }
      }
    });
  }

  app.videRes = null;
  function onBarba(parent) {
    try {
      activeNav();
      setRegoLookupFields(parent);
      if (parent) {
        swipers(parent);
        setSmooth(parent);
        setLazy(parent);
        apps(parent);
        setModals(parent);
        setButtons(parent);
        inputs(parent);
        setAutocompletes(parent);
        equal_team_cols(parent);
        nav_phone(parent);
        app.spoilers = new Spoilers(parent);
        app.videRes = new videoResize(".has-cover-video");
      }
    } catch (e) {
      console.error(e)
    }
  }

  select.all(".fui-form").forEach(setFormValidations);
  doc.addEventListener("onFormieInit", (e) => {
    app.forms = e.detail.formie;
    //only run on initial page load - subsequent page changes i..e barber use a different hook to run the setFormEvents
    setFormEvents(app.forms);
  });

  doc.addEventListener("DOMContentLoaded", function () {
    outBarba();
    cl(".overlay").rmCl("open");
    if (app.nav) app.nav.scroll();
    buttonStyles();
    parallaxInit();

    win.addEventListener(
      "scroll",
      throttle(function () {
        if (app.nav) app.nav.scroll();
      }, 50)
    );

    win.addEventListener(
      "resize",
      throttle(function () {
        if (app.nav) app.nav.close();
        if (app.videRes) app.videRes.resize();
        equal_team_cols();
      }, 300)
    );

    win.addEventListener("keydown", (ev) => {
      if (ev.key === "Escape") {
        if (app.nav) app.nav.close();
        window.modals.forEach((m) => m.close());
        cl(".block-search.is-global").rmCl("show-search");
      }
    });

    barba.init({
      timeout: 15000,
      //prefetchIgnore: true,
      transitions: [
        {
          from: {
            custom() {
              return window.openModal === false;
            },
          },
          name: "transition",
          leave({ current }) {
            const done = this.async();
            cl(".overlay").addCl("open");
            setTimeout(() => {
              done();
            }, 200);
          },
          enter() {
            cl(".overlay").rmCl("open");
          },
        },
      ],
    });

    barba.hooks.before(() => {
      cl("html").addCl("is-loading");
      cl(".header").rmCl("is-light");
      cl(".block-search.is-global").rmCl("show-search");
      cl(".header").rmCl("search-is-visible");
    });

    barba.hooks.beforeEnter(() => {
      window.scrollTo(0, 0);
      buttonStyles();
    });

    barba.hooks.leave(() => {
      if (app.nav) {
        app.nav.leave();
      }
      if (app.forms && app.forms.forms.length) {
        app.forms.forms.forEach((form) => {
          try {
            if (form.$registeredJs) {
              form.$registeredJs.remove();
            }

            if (!isEmpty(form.listeners)) {
              Object.keys(form.listeners).forEach((eventKey) => {
                form.removeEventListener(eventKey);
              });
            }

            if (form.formTheme && form.formTheme.validator) {
              form.formTheme.validator.destroy();
            }
          } catch (e) {
            console.error(e);
          }
        });
      }
      window.modals.forEach((m) => m.close());
      parallaxRemove();
    });

    barba.hooks.enter(({ next }) => {
      if (app.nav) {
        app.nav.update(next);
        app.nav.close();
        initForm(next.container);
      }
    });

    barba.hooks.afterEnter(({ next }) => {
      onBarba(next.container);
      scUnlock();
      select.all("video", next.container).forEach((v) => {
        if (v.autoplay) v.play();
      });
      cl("html").rmCl("is-loading");
      // nav color mode
      if (next.container.dataset.navColor == "light") {
        cl(".header").addCl("is-light");
      }
      setTimeout(() => {
        parallaxAdd();
      }, 200);
    });
  });
})(document, window);
